/**
 * This composable is used to inform screen readers that the page title has been updated during CSR navigation.
 * - To ensure that screen readers are notified when the page title changes, we used a hidden element in the DOM with aria-live attributes.
 * - While screen readers not announce changes to the <title> tag when title is changed while navigating(CSR), the hidden element will announce the changes.
 * - Using an aria-live region ensures that the changes in the div are communicated to the screen reader properly.
 * - pageTitleElement is the hidden element in the DOM that is used to announce the page title changes.
 */
export const usePageTitle = () => {
  const setPageTitle = (title: string) => {
    if (process.client) {
      const pageTitleElement = document.getElementById('page-title');
      if (pageTitleElement) {
        pageTitleElement.textContent = title;
      }
    }
  };
  return {
    setPageTitle
  };
};
